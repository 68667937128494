export default {
    area: "石油社区",
    objectName: "",
    //url
    hostUrl : 'https://gljdapp.zhsq.cloud/',
    apiHttpsUrl : 'https://gljdapi.zhsq.cloud/',
    apiRequestUrl : 'https://gljdapi.zhsq.cloud/api',
    apiUploadUrl: 'https://gljdapi.zhsq.cloud/api/UploadFile/UploadImgs',
    apiUploadFiles:'https://gljdapi.zhsq.cloud/api/UploadFile/UploadFiles',
    apiUploadvideo:'https://gljdapi.zhsq.cloud/api/UploadFile/UploadQiniuVideo',
}